<template>
    <div id="print-templates-editor-layout">
        <div v-if="options.colors" id="edit-print-template" class="row " >
            <div class="dp col-md-7 p-3" style="background-color:#fff!important;max-height:70vh; overflow-x:scroll">
                <div class="print-intro row" :style="`background-color:${options.colors.headerBackground}; padding:15px;margin-bottom:20px;`">
                    <div class="col-md-6 col-2-3" :style="`color:${options.colors.headerTextColor}`">
                        <span v-if="options.showDate"><strong> {{ options.translations.titles.date }}: </strong>{{ new Date().getDate() + '/' + (parseInt(new Date().getMonth()) + 1) + '/' + new Date().getFullYear() }}<br></span>
                        <span v-if="options.showDietitianName"><strong>{{ options.translations.titles.dietitian }}:</strong> {{user.firstName + ' ' + user.lastName}} </span>
                        <br>
                        <span v-if="options.showDietPlanName"><strong > {{ options.translations.titles.plan }}: </strong>Πρότυπο διατροφικό πλάνο</span>
                    </div>
                    <div v-if="options.showLogo" class="col-md-6 col-1-3 text-right" >
                        <img :src="user.logo ? user.logo : 'https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-icon-printing.png'"
                             width="150"
                             style="width:auto;max-width:150px;max-height:75px;"
                             class="pr-3"/>
                    </div>

                </div>
                <div class="diet-plan-table-wrapper" style="background-color:#fff!important;">
                    <table id="dietPlan-table" class="table table-responsive table-borderless">
                        <tbody id="print-diet-plan-table">
                        <tr v-for="(row, idx1) in dietMeals" :key="idx1" :style="getTRStyles(idx1)">

                            <th class="table-meal-day-name" :style="`background-color:${options.colors.tableHeader};color:${options.colors.text.leftTitles};letter-spacing: ${options.letterSpacing.leftTitles}px;font-size:${options.sizes.text.leftTitles}px;`">
                                {{options.translations.dayNames[idx1]}}</th>

                            <td v-for="(col, idx2) in row" :key="idx2" class="table-meal" :style="getTDStyles(idx1, idx2)">
                                <div class="row-header day" :style="`font-size: ${options.sizes.text.topTitles}px;letter-spacing: ${options.letterSpacing.topTitles}px;
                        margin-bottom: ${options.bottomSpacing.topTitles}px;background-color:${options.colors.topTitleBg};
                        color: ${options.colors.text.topTitles};text-align:center;font-weight:bold;}`">
                                    {{ options.translations.dayMeals[idx2] }}

                                </div>

                                <div class="row-content" :class="options.showCategoryColors ? 'category-colored' :''">

                                    <div class="table-meal-food meal-category-1" :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                    letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
                                        Ντοματίνια<span v-if="options.showQuantities">:  60γρ.</span>
                                    </div>
                                    <div class="table-meal-food meal-category-2" :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                    letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
                                        Παξιμάδια<span v-if="options.showQuantities">:  4 τμχ.</span>
                                    </div>
                                    <div class="table-meal-food meal-category-3" :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                    letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
                                        Ελαιόλαδο<span v-if="options.showQuantities">: 1 κ.σ.</span><br>
                                    </div>

                                    <div class="table-meal-food meal-category-4" :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                    letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
                                        Γραβιέρα<span v-if="options.showQuantities">: 60γρ.</span><br>
                                    </div>

                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <b-card class="col-md-5" style="max-height:70vh; overflow-x:scroll">
                <b-card-title>Επεξεργασία προτύπου</b-card-title>
                <b-card-sub-title>Επεξεργαστείτε το πρότυπο της εκτύπωσής σας</b-card-sub-title>

                <label class="mt-2">
                    Όνομα προτύπου:
                    <b-input v-model="template.name" type="text"></b-input>
                </label>

                <b-card-body>
                    <b-tabs content-class="mt-3" justified>
                        <b-tab v-click-outside="() => { this.selectedField = null } " title="Χρώματα" active>

                            <div class="options-bg">

                                <h4>Background:</h4>

                                <b-form inline>
                                    <div class="choose-header-color mt-2">
                                            Χρώμα κεφαλίδας:

                                            <b-input v-model="options.colors.headerBackground"
                                                     class="ml-3"
                                                     :style="`background-color:${options.colors.headerBackground};color:#fff`"
                                                     @click="selectedField = 1"></b-input>
                                        <ColorPicker v-if="selectedField === 1" v-model="options.colors.headerBackground" @colorChanged="val => { options.colors.headerBackground = val }"
                                                     @closeModal="selectedField = null"/>

                                    </div>
                                    <div class="choose-day-color mt-1">
                                        Χρώμα ημέρων:
                                        <b-input  v-model="options.colors.tableHeader" class="ml-3"
                                                  :style="`background-color:${options.colors.tableHeader};color:#fff`"
                                                  @click="selectedField = 2"></b-input>
                                        <ColorPicker v-if="selectedField === 2" v-model="options.colors.tableHeader" @colorChanged="val => { options.colors.tableHeader = val }"
                                                     @closeModal="selectedField = null"/>
                                    </div>
                                    <div class="choose-stripes-color mt-1">
                                        Ρίγες πίνακα:
                                        <b-input  v-model="options.colors.stripes" class="ml-3"
                                                  :style="`background-color:${options.colors.stripes};color:${options.colors.text.foodText}`"
                                                  @click="selectedField = 3"></b-input>
                                        <ColorPicker v-if="selectedField === 3" v-model="options.colors.stripes" @colorChanged="val => { options.colors.stripes = val }"
                                                     @closeModal="selectedField = null"/>
                                    </div>

                                    <div class="choose-borders mt-1">
                                        Περιγράμματα πίνακα:
                                        <b-input  v-model="options.colors.border" class="ml-3"
                                                  :style="`color:${options.colors.border};background-color:#fff`"
                                                  @click="selectedField = 4 "></b-input>
                                        <ColorPicker v-show="selectedField === 4"
                                                     v-model="options.colors.border"
                                                     @colorChanged="val => { options.colors.border = val }"
                                                     @closeModal="selectedField = null"/>
                                    </div>

                                    <hr/>
                                </b-form>
                            </div>

                            <div class="options-bg">

                                <h4>Κείμενο:</h4>

                                <b-form inline>
                                    <div class="choose-header-color mt-2">
                                        Πληροφορίες:
                                        <b-input v-model="options.colors.headerTextColor"
                                                 class="ml-3"
                                                 :style="`color:${options.colors.headerTextColor};background-color:#fff`"
                                                 @click="selectedField =7"></b-input>

                                        <ColorPicker v-if="selectedField === 7" v-model="options.colors.headerTextColor" @colorChanged="val => { options.colors.headerTextColor = val }"
                                                     @closeModal="selectedField = null"/>

                                    </div>
                                    <div class="choose-header-color mt-2">
                                        Γεύματα:
                                        <b-input v-model="options.colors.text.topTitles"
                                                 class="ml-3"
                                                 :style="`color:${options.colors.text.topTitles};background-color:#fff`"
                                                 @click="selectedField = 5"></b-input>

                                        <ColorPicker v-if="selectedField === 5" v-model="options.colors.text.topTitles" @colorChanged="val => { options.colors.text.topTitles = val }"
                                                     @closeModal="selectedField = null"/>

                                    </div>

                                    <div class="choose-header-color mt-2">
                                        Ημέρες (ή επιλογές):
                                        <b-input v-model="options.colors.text.leftTitles"
                                                 class="ml-3"
                                                 :style="`background-color:${options.colors.text.leftTitles};color:#000`"
                                                 @click="selectedField =6"></b-input>

                                        <ColorPicker v-if="selectedField === 6" v-model="options.colors.text.leftTitles" @colorChanged="val => { options.colors.text.leftTitles = val }"
                                                     @closeModal="selectedField = null"/>

                                    </div>

                                    <div class="choose-header-color mt-2">
                                        Τρόφιμα:
                                        <b-input v-model="options.colors.text.foodText"
                                                 class="ml-3"
                                                 :style="`color:${options.colors.text.foodText};background-color:#fff`"
                                                 @click="selectedField =8"></b-input>

                                        <ColorPicker v-if="selectedField === 8" v-model="options.colors.text.foodText" @colorChanged="val => { options.colors.text.foodText = val }"
                                                     @closeModal="selectedField = null"/>

                                    </div>


                                    <hr/>
                                </b-form>
                            </div>
                        </b-tab>

                        <b-tab title="Πίνακας">
                            <div class="options-bg">
                                <div class="choose-border mt-3">
                                    Χρωματισμός τροφίμων με βάση την κατηγορία τους: <input v-model="options.showCategoryColors" type="checkbox"/>
                                </div>
                                <div class="choose-border mt-3">
                                    Περίγραμμα πίνακα: <input v-model="options.hasBorder" type="checkbox"/>
                                </div>

                                <div class="choose-stripes mt-3">

                                    Ρίγες:
                                    <select id="select-stripes" v-model="options.stripes" class="custom-select block">
                                        <option value='none'>Χωρίς ρίγες</option>
                                        <option value='columns'>Στήλες</option>
                                        <option value='rows'>Γραμμές</option>
                                        <option value='mix'>Σκακιέρα</option>
                                    </select>

                                </div>
                            </div>
                        </b-tab>

                        <b-tab title="Κείμενο">
                            <b-form inline>
                                <div class="options-bg mt-2 mb-2">
                                    <h5>Ημέρες</h5>
                                    <div>
                                        <div class="choose-header-color mt-2">
                                            Μέγεθος ημερών:
                                            <b-input v-model="options.sizes.text.leftTitles" type="number" class="ml-3"></b-input>
                                        </div>

                                    </div>
                                </div>

                                <div class="options-bg mt-2 mb-2">
                                    <h5>Τρόφιμα</h5>
                                    <div class="choose-header-color mt-2">
                                        <div>
                                            Μέγεθος τροφίμων:
                                            <b-input v-model="options.sizes.text.foodText" type="number" class="ml-3"></b-input>
                                        </div>
                                        <div class="mt-3">
                                            Αποστάση γραμμάτων:
                                            <b-input v-model="options.letterSpacing.foodText" type="number" step="0.1" class="ml-3"></b-input>
                                        </div>

                                    </div>
                                </div>
                                <div class="options-bg mt-2 mb-2">
                                    <h5>Γεύματα</h5>
                                    <div>
                                        <div class="choose-header-color mt-2">
                                            Μέγεθος γευμάτων:
                                            <b-input v-model="options.sizes.text.topTitles" type="number" class="ml-3"></b-input>
                                        </div>

                                    </div>
                                </div>


                            </b-form>
                        </b-tab>
<!--                        <b-tab title="Αποστάσεις">-->
<!--                            <b-form inline>-->
<!--                                <div class="choose-header-color mt-2">-->
<!--                                    Μέγεθος ημερών:-->
<!--                                    <b-input v-model="options.sizes.text.leftTitles" type="number" class="ml-3"></b-input>-->
<!--                                </div>-->
<!--                                <div class="choose-header-color mt-2">-->
<!--                                    Μέγεθος τροφίμων:-->
<!--                                    <b-input v-model="options.sizes.text.foodText" type="number" class="ml-3"></b-input>-->
<!--                                </div>-->
<!--                                <div class="choose-header-color mt-2">-->
<!--                                    Μέγεθος γευμάτων:-->
<!--                                    <b-input v-model="options.sizes.text.topTitles" type="number" class="ml-3"></b-input>-->
<!--                                </div>-->
<!--                            </b-form>-->
<!--                        </b-tab>-->

                        <b-tab title="Ρυθμίσεις">
                            Προσανατολισμός εκτύπωσης:
                            <select id="orientation-select" v-model="options.orientation" class="custom-select mb-3">
                                <option value='normal'>Κατακόρυφα (portrait) </option>
                                <option value='landscape'>Οριζόντια (landscape)</option>
                            </select>
                            <div class="options-bg">

                                <h5>Γενικές ρυθμίσεις</h5>
                                <b-form-checkbox id="showDietitianName" v-model="options.showDietitianName" name="showDietitianName">Εμφάνιση του ονόματός σας</b-form-checkbox>
                                <b-form-checkbox id="showDate" v-model="options.showDate" name="showDate">Εμφάνιση ημερομηνίας</b-form-checkbox>
                                <b-form-checkbox id="showNotes" v-model="options.showNotes" name="showNotes">Εμφάνιση σημειώσεων (σε νέα σελίδα)</b-form-checkbox>
                              <b-form-checkbox id="showLogo" v-model="options.showLogo" name="showLogo">Εμφάνιση Λογοτύπου</b-form-checkbox>
                            </div>
                            <div class="options-bg">
                                <h5>Διατροφικό πλάνο</h5>
                                <b-form-checkbox id="showQuantity" v-model="options.showQuantities" name="showQuantity">Εμφάνιση ποσοτήτων τροφίμων</b-form-checkbox>
                                <b-form-checkbox id="showAnalysis" v-model="options.showAnalysis" name="showAnalysis">Εμφάνιση της ανάλυσης του πλάνου</b-form-checkbox>
                                <b-form-checkbox id="showExercises" v-model="options.showExercises" name="showExercises">Εμφάνιση της εβδομαδιαίας άσκησης</b-form-checkbox>
                            </div>
                            <div class="options-bg">
                                <h5> Λίστα για τα ψώνια</h5>
                                <b-form-checkbox id="shoppingList" v-model="options.shoppingList.show" name="showshoppingList">Εκτύπωση λίστας τροφίμων για ψώνια;</b-form-checkbox>
                                <div v-if="options.shoppingList.show">
                                    <b-form-checkbox id="recipesShopping" v-model="options.shoppingList.showRecipes" name="recipesShoppingL">Εκτύπωση σύνθετων τροφίμων;</b-form-checkbox>
                                    <b-form-checkbox id="personalNotes" v-model="options.shoppingList.showNotForgetList" name="personalNotesL">Εκτύπωση λίστας "μην ξεχάσω...";</b-form-checkbox>
                                </div>
                            </div>

                        </b-tab>

                        <b-tab title="Μετάφραση">
                          <div class="options-bg">
                            <h5>Τίτλοι</h5>
                            <label> Όνομα πλάνου
                             <b-form-input v-model="options.translations.titles.plan"></b-form-input></label>
                            <label> Ημερομηνία
                              <b-form-input v-model="options.translations.titles.date"></b-form-input></label>
                            <label> Διατροφολόγος
                              <b-form-input v-model="options.translations.titles.dietitian"></b-form-input></label>
                          </div>
                          <div class="options-bg">
                            <h5 class="mt-2">Ονόματα ημερών</h5>
                            <label> Δευτέρα
                              <b-form-input v-model="options.translations.dayNames[0]"></b-form-input></label>
                            <label> Τρίτη
                              <b-form-input v-model="options.translations.dayNames[1]"></b-form-input></label>
                            <label> Τετάρτη
                              <b-form-input v-model="options.translations.dayNames[2]"></b-form-input></label>
                            <label> Πέμπτη
                              <b-form-input v-model="options.translations.dayNames[3]"></b-form-input></label>
                            <label> Παρασκευή
                              <b-form-input v-model="options.translations.dayNames[4]"></b-form-input></label>
                            <label> Σάββατο
                              <b-form-input v-model="options.translations.dayNames[5]"></b-form-input></label>
                            <label> Κυριακή
                              <b-form-input v-model="options.translations.dayNames[6]"></b-form-input></label>
                          </div>
                          <div class="options-bg">
                            <h5 class="mt-2">Ονόματα γευμάτων</h5>
                            <label> Πρωϊνό
                              <b-form-input v-model="options.translations.dayMeals[0]"></b-form-input></label>
                            <label> Δεκατιανό
                              <b-form-input v-model="options.translations.dayMeals[1]"></b-form-input></label>
                            <label> Μεσημεριανό
                              <b-form-input v-model="options.translations.dayMeals[2]"></b-form-input></label>
                            <label> Απογευματινό
                              <b-form-input v-model="options.translations.dayMeals[3]"></b-form-input></label>
                            <label> Βραδινό
                              <b-form-input v-model="options.translations.dayMeals[4]"></b-form-input></label>

                          </div>
                          <div class="options-bg">
                            <h5 class="mt-2">Μεζούρες</h5>
                            <label> Τεμάχιο (μεγάλο)
                              <b-form-input v-model="options.translations.measures[1]"></b-form-input></label>
                            <label> Τεμάχιο (μικρό)
                              <b-form-input v-model="options.translations.measures[2]"></b-form-input></label>
                            <label> Τεμάχιο
                              <b-form-input v-model="options.translations.measures[3]"></b-form-input></label>
                            <label> Φλιτζάνι
                              <b-form-input v-model="options.translations.measures[4]"></b-form-input></label>
                            <label> Κουταλάκι γλυκού
                              <b-form-input v-model="options.translations.measures[6]"></b-form-input></label>
                            <label> Κουταλιά σούπας
                              <b-form-input v-model="options.translations.measures[7]"></b-form-input></label>
                            <label> Γραμμάρια
                              <b-form-input v-model="options.translations.measures[8]"></b-form-input></label>
                            <label> Φλιτζάνι τσαγιού
                              <b-form-input v-model="options.translations.measures[9]"></b-form-input></label>
                            <label> Τεμάχιο (μέτριο)
                              <b-form-input v-model="options.translations.measures[10]"></b-form-input></label>
                            <label> ml
                              <b-form-input v-model="options.translations.measures[11]"></b-form-input></label>
                            <label> Scoop
                              <b-form-input v-model="options.translations.measures[12]"></b-form-input></label>
                          </div>

                          <div class="options-bg">
                            <h5 class="mt-2">Άλλα κείμενα</h5>
                            <label> Λίστα για ψώνια
                              <b-form-input v-model="options.translations.texts.shoppingList"></b-form-input></label>
                            <label> Μην ξεχάσετε επίσης τα υλικά των συνταγών...
                              <b-form-input v-model="options.translations.texts.dontForget"></b-form-input></label>
                            <label> Μην ξεχάσω...
                              <b-form-input v-model="options.translations.texts.doNotForget"></b-form-input></label>
                            <label> Σημειώσεις
                              <b-form-input v-model="options.translations.texts.notes"></b-form-input></label>
                            <label> Ενέργεια
                              <b-form-input v-model="options.translations.texts.energy"></b-form-input></label>
                            <label> Υδατάνθρακες
                              <b-form-input v-model="options.translations.texts.carbs"></b-form-input></label>
                            <label> Πρωτεϊνες
                              <b-form-input v-model="options.translations.texts.proteins"></b-form-input></label>
                            <label> Φρέσκιες
                              <b-form-input v-model="options.translations.texts.freskies"></b-form-input></label>
                            <label> Αμυλούχες
                              <b-form-input v-model="options.translations.texts.amylouxes"></b-form-input></label>
                            <label> Λίπη
                              <b-form-input v-model="options.translations.texts.fats"></b-form-input></label>
                            <label> Ζωϊκές
                              <b-form-input v-model="options.translations.texts.zoikes"></b-form-input></label>
                            <label> Σύνθετα
                              <b-form-input v-model="options.translations.texts.sintheta"></b-form-input></label>
                            <label> Στατιστικά Τροφίμων
                              <b-form-input v-model="options.translations.texts.stats"></b-form-input></label>
                          </div>
                        </b-tab>

                        <div class="text-right">
                            <save-button @click="updateTemplate" :disabled="!hasChanged"></save-button>
                        </div>
                    </b-tabs>
                </b-card-body>


            </b-card>
        </div>
    </div>

</template>
<style lang="scss">
#edit-print-template{
    #print-diet-plan-table{
        .row-header, .table-meal-food{
            background-color:transparent;
            &:hover{
                background-color:unset;
            }
        }
        .table-meal{
            cursor:initial;
            &:hover{
                transform:none;
                box-shadow: none;
            }
        }
        td{width:18%}

    }
    .category-colored{
        .meal-category-1 {
            color: #fff!important;
            background-color: #1abc9c!important;
            margin: 2px;
            padding: 1px;
        }

        .meal-category-2 {
            color:#fff!important;
            background-color:#f7b84b!important;
            margin: 2px;
            padding: 1px;
        }
        .meal-category-3 {
            color:#fff!important;
            background-color:#f672a7!important;
            margin: 2px;
            padding: 1px;
        }
        .meal-category-4 {
            color:#fff!important;
            background-color:$color_primary!important;
            margin: 2px;
            padding: 1px;
        }
    }

    .col-md-7{
        padding:0;
    }
    .options-bg{
        background-color:#f4f5f7;
        padding:10px 20px;
        margin-bottom:10px;
    }
    label{
        font-weight:300 !important;
    }
    .square{
        width:30px;
        height: 30px;
    }

}
</style>
<script>
import ColorPicker from '@components/ColorPicker'
import Vue from 'vue'
import {mapState} from "vuex";
import vClickOutside from "v-click-outside";

export default {

    components: {ColorPicker},
    page: {title: 'Λογαριασμός'},
    directives: {
      clickOutside: vClickOutside.directive
    },

    data() {
        return {
            templateId: this.$route.params.templateId,
            dietMeals: [[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]], [[],[],[],[],[]],[[],[],[],[],[]]],
            template: {},
            templates: this.$store ? (this.$store.state.auth.printTemplates || []) : [] || [],
            options: null,
            colors:{},
            selectedField: null,
            initialState: null,
            initialName: null
        }
    },

    computed:{
      ...mapState({
        user: state => state.auth.currentUser
      }),
        hasChanged(){
            return (this.initialState && this.initialName) ? (this.initialState !== JSON.stringify(this.options)) || (this.template.name !== this.initialName) : false;
        }
    },

    async created() {
        if(this.templates.length === 0){
            await this.$store.dispatch('auth/getPrintTemplates');
        }
        this.initTemplate();


    },

    methods:{

        initTemplate(){
            this.template = JSON.parse(JSON.stringify(this.templates.find(x => {return x.id === parseInt(this.templateId)})));

          this.$store.commit('ui/setActiveItem', {
            value: this.template.name,
            index: 0
          });
            this.options = JSON.parse(JSON.stringify(this.template.options));
            if(!this.options.hasOwnProperty('showNotes')) Vue.set(this.options, 'notes', true);
            if(!this.options.hasOwnProperty('showLogo')) Vue.set(this.options, 'showLogo', true);
          if(!this.options.hasOwnProperty('translations') || !this.options.translations.hasOwnProperty('measures') || !this.options.translations.hasOwnProperty('texts')) Vue.set(this.options, 'translations', {
            dayNames: {
              0: 'Δευτέρα',
              1: 'Τρίτη',
              2: 'Τετάρτη',
              3: 'Πέμπτη',
              4: 'Παρασκευή',
              5: 'Σάββατο',
              6: 'Κυριακή'
            },
            dayMeals: {
              0: 'Πρωϊνό',
              1: 'Δεκατιανό',
              2: 'Μεσημεριανό',
              3: 'Απογευματινό',
              4: 'Βραδινό'
            },
            titles:{
              plan: 'Όνομα πλάνου',
              date: 'Ημερομηνία',
              dietitian: 'Διατροφολόγος',
            },
            measures: {
              1:'τεμ. (μεγ.)',
              2:'τεμ. (μικ.)',
              3:'τεμ.',
              4:'φλ.',
              6:'κ.γ.',
              7:'κ.σ.',
              8:'γρ.',
              9:'φλ. τσ.',
              10:'τεμ. (μετρ.)',
              11:'ml',
              12:'scoop',
            },
            texts:{
              energy: 'Ενέργεια',
              carbs: 'Υδατάνθρακες',
              proteins: 'Πρωτεϊνες',
              fats: 'Λίπη',
              freskies: 'Φρέσκιες',
              amylouxes: 'Αμυλούχες',
              lipi: 'Λίπη',
              zoikes: 'Ζωϊκές',
              sintheta:'Σύνθετα',
              notes:'Σημειώσεις',
              shoppingList: 'Λίστα με ψώνια',
              dontForget: 'Μην ξεχάσετε επίσης τα υλικά των συνταγών...',
              doNotForget: 'Μην ξεχάσω...',
              stats: 'Στατιστικά τροφίμων'
            }
          });

          this.initialState = JSON.stringify(this.options);
            this.initialName = this.template.name;
        },

        getTRStyles(idx1, idx2){
            if(this.options.stripes === 'rows'){
                if(idx1 % 2 === 0){
                    return `background-color:${this.options.colors.stripes};`;
                }
            }

            return '';

        },

        getTDStyles(idx1, idx2){

            let returnable = '';
            if(this.options.stripes === 'mix'){
                if(idx1 % 2 !== 0 && idx2 % 2 === 0){
                    returnable = `background-color:${this.options.colors.stripes};`;
                }
                if(idx1 % 2 === 0 && idx2 % 2 !== 0){
                    returnable = `background-color:${this.options.colors.stripes};`;
                }
            }

            if(this.options.stripes === 'columns'){
                if(idx2 % 2 !== 0 )  returnable = `background-color:${this.options.colors.stripes};`;
            }

            if(this.options.hasBorder) returnable += `border: 1px solid ${this.options.colors.border}!important;}`;

            return returnable;

        },

        updateTemplate(){
            this.template.options = this.options;
            this.$store.dispatch('auth/updatePrintTemplate', this.template).then( res => {
                this.initTemplate();
                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το πρότυπο αποθηκεύτηκε επιτυχώς!'});
            }).catch( () => {
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ προσπαθήστε ξανά!'});
            })
        }
    }

}
</script>
